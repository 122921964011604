import 'remodal';
import loaderHtml from 'components/loader';
import ProfileCard from 'components/profile-card';
import RecoverPassword from 'components/recover-password';
import AuthCard from 'components/auth-card';

'use strict';

const MenuPopup = {

    /**
     * Initialize the MenuPopup features
     */
    init(options) {

        this.options = options = options || {};

        this.$popup = $('#menu-popup');
        this.$modal = this.$popup.remodal({ hashTracking: false });

        this.$popup.append(`<div class="loader-overlay">${loaderHtml}</div>`);

        options.activeLink && this.$popup.find(`.js-link-${options.activeLink}`).addClass('active');

        if (options.isLoggedIn) {
            ProfileCard.init({
                openLoader: this.openLoader.bind(this),
                closeLoader: this.closeLoader.bind(this),
                userMe: this.options.userMe
            });
        }
        else {
            AuthCard.init({
                cities: this.options.cities,
                openLoader: this.openLoader.bind(this),
                closeLoader: this.closeLoader.bind(this)
            });
            RecoverPassword.init({
                reOpen: this.$modal,
                feederField: $('#log-email')
            });
        }

        this.bindEvents();
    },


    /**
     * Display loader
     */
    openLoader() {
        this.$popup.addClass('is-loading');
    },


    /**
     * Hide loader
     */
    closeLoader() {
        this.$popup.removeClass('is-loading');
    },


    /**
     * Open main popup
     * @param e {Event} click event object
     */
    openPopup(e) {
        e && e.preventDefault();
        this.$modal.open();
    },


    /**
     * Close main popup
     * @param e {Event} click event object
     */
    closePopup(e) {
        e && e.preventDefault();
        this.$modal.close();
    },


    /**
     * Bind Events
     */
    bindEvents() {
        $('.js-open-popup-menu').on('click', this.openPopup.bind(this));
        $('.js-close-popup-menu').on('click', this.closePopup.bind(this));
    }
};


export default MenuPopup;
