const loaderHtml = `<div class="loader">
                    <div class="loader-msg">Loading...</div>
                    <svg class="circular">
                        <circle
                            class="path"
                            cx="50"
                            cy="50"
                            r="20"
                            fill="none"
                            stroke-width="4"
                            stroke-miter-limit="10"/>
                    </svg>
                </div>`;

export default loaderHtml;
