import 'remodal';
import loaderHtml from 'components/loader';

'use strict';

const AdminPopup = {

    /**
     * Initialize the MenuPopup features
     */
    init(options) {

        options = options || {};

        this.$popup = $('#admin-popup');
        this.$modal = this.$popup.remodal({ hashTracking: false });

        this.$popup.append(`<div class="loader-overlay">${loaderHtml}</div>`);

        if (options.isAdmin || options.isManager || options.isSupplier || options.isAmbassador || options.isPhotographer) {
            $('.js-open-popup-admin').removeClass('hidden');
        }
        else {
            return;
        }

        let thisCity = options.userMe.cities[0].name.split(' ');
        let city = thisCity.join('');

        let link = 'https://www.facebook.com/groups/ML.Team.' + city;

        options.activeLink && this.$popup.find(`.js-link-${options.activeLink}`).addClass('active');

        if (options.isAdmin) {
            this.$popup.find('.text').text('Manage');
            this.$popup.find('.js-link-ambassadors').addClass('hidden');
            this.$popup.find('.js-link-worldwide').addClass('hidden');
            this.$popup.find('.js-link-chat').addClass('hidden');
            this.$popup.find('.js-link-grow-manual').addClass('hidden');
            this.$popup.find('.js-link-grow-feedback').addClass('hidden');
            this.$popup.find('.js-link-grow-photographersGuide').addClass('hidden');
            this.$popup.find('.js-link-darkroom').addClass('hidden');
            this.$popup.find('.js-link-grow-eomGuides').addClass('hidden');
            this.$popup.find('.js-link-grow-venue-prest').addClass('hidden');
            this.$popup.find('.js-link-products').closest('li').addClass('hidden');

            this.$popup.find('.menu-column__title--products').text('Manage');
            this.$popup.find('.js-link-orders').attr('href', 'orders');
            this.$popup.find('.js-link-delivery').attr('href', 'delivery-groups');
            this.$popup.find('.js-link-finance-event').attr('href', 'events-finance-admin');
            this.$popup.find('.js-link-finance-page').attr('href', 'finance-admin');
            this.$popup.find('.js-link-marketing').attr('href', 'design-request');
            this.$popup.find('.js-link-emails').attr('href', 'send-emails');
        }
        else if (options.isSupplier) {
            this.$popup.find('.text').text('Products');
            this.$popup.find('.menu-column__title--finance').text('Management');
            this.$popup.find('.js-link-ambassadors').addClass('hidden');
            this.$popup.find('.js-link-worldwide').addClass('hidden');
            this.$popup.find('.js-link-chat').addClass('hidden');
            this.$popup.find('.js-link-grow-manual').addClass('hidden');
            this.$popup.find('.js-link-grow-feedback').addClass('hidden');
            this.$popup.find('.js-link-grow-photographersGuide').addClass('hidden');
            this.$popup.find('.js-link-darkroom').addClass('hidden');
            this.$popup.find('.js-link-emails').addClass('hidden');
            this.$popup.find('.js-link-grow-eomGuides').addClass('hidden');
            this.$popup.find('.js-link-grow-venue-prest').addClass('hidden');

            this.$popup.find('.js-link-products').text('Inventory').attr('href', 'products');
            this.$popup.find('.js-link-orders').attr('href', 'orders');
            this.$popup.find('.js-link-delivery').attr('href', 'delivery-groups');
            this.$popup.find('.js-link-finance-event').text('Finances').attr('href', 'events-finance-supplier');
            this.$popup.find('.js-link-finance-page').attr('href', 'finance-supplier');
            this.$popup.find('.js-link-marketing').addClass('hidden');
        }
        else if (options.isAmbassador) {
            this.$popup.find('.text').text('Community');
            this.$popup.find('.js-link-products').closest('li').addClass('hidden');
            this.$popup.find('.js-link-orders').closest('li').addClass('hidden');
            this.$popup.find('.js-link-delivery').addClass('hidden');
            this.$popup.find('.js-link-finance-event').addClass('hidden');
            this.$popup.find('.js-link-finance-page').addClass('hidden');
            this.$popup.find('.js-link-marketing').attr('href', 'design-request');
            this.$popup.find('.js-link-emails').addClass('hidden');
            this.$popup.find('.js-link-grow-photographersGuide').addClass('hidden');
            this.$popup.find('.js-link-darkroom').addClass('hidden');
            this.$popup.find('.js-link-grow-eomGuides').addClass('hidden');
            this.$popup.find('.js-link-grow-venue-prest').addClass('hidden');

            this.$popup.find('.menu-column__title--finance').text('Grow');

            this.$popup.find('.js-link-grow-manual').attr('href', 'https://www.dropbox.com/sh/y0w772r4td0hl8f/AAAp_-DpmQYS_yPa8jpON91sa?dl=0');
            this.$popup.find('.js-link-grow-feedback').attr('href', 'ambassador-report');
            this.$popup.find('.js-link-chat').attr('href', 'https://t.me/mundolingo');
            this.$popup.find('.js-link-worldwide').attr('href', 'https://www.facebook.com/groups/MundoLingo.Ambassadors/');
            this.$popup.find('.js-link-ambassadors').attr('href', link);
        }
        else if (options.isPhotographer) {
            this.$popup.find('.text').text('Community');
            this.$popup.find('.js-link-ambassadors').addClass('hidden');
            this.$popup.find('.js-link-worldwide').addClass('hidden');
            this.$popup.find('.js-link-chat').addClass('hidden');
            this.$popup.find('.js-link-grow-manual').addClass('hidden');
            this.$popup.find('.js-link-products').closest('li').addClass('hidden');
            this.$popup.find('.js-link-orders').closest('li').addClass('hidden');
            this.$popup.find('.js-link-delivery').addClass('hidden');
            this.$popup.find('.js-link-finance-event').addClass('hidden');
            this.$popup.find('.js-link-finance-page').addClass('hidden');
            this.$popup.find('.js-link-marketing').addClass('hidden');
            this.$popup.find('.js-link-emails').addClass('hidden');
            this.$popup.find('.js-link-grow-eomGuides').addClass('hidden');
            this.$popup.find('.js-link-grow-venue-prest').addClass('hidden');

            this.$popup.find('.menu-column__title--finance').text('Grow');
            this.$popup.find('.menu-column__title menu-column__title--marketing').addClass('hidden');

            this.$popup.find('.js-link-darkroom').attr('href', 'https://www.facebook.com/groups/ML.Darkroom/');
            this.$popup.find('.js-link-grow-photographersGuide').attr('href', 'https://www.dropbox.com/s/ef3vcicpl988f4r/Talent.Photography.pdf?dl=0');
            this.$popup.find('.js-link-grow-feedback').attr('href', 'darkroom');


        }
        else {
            this.$popup.find('.text').text('City Admin');
            this.$popup.find('.js-link-ambassadors').addClass('hidden');
            this.$popup.find('.js-link-worldwide').addClass('hidden');
            this.$popup.find('.js-link-chat').addClass('hidden');
            this.$popup.find('.js-link-grow-manual').addClass('hidden');
            this.$popup.find('.js-link-grow-photographersGuide').addClass('hidden');
            this.$popup.find('.js-link-darkroom').addClass('hidden');
            this.$popup.find('.js-link-emails').addClass('hidden');

            this.$popup.find('.js-link-products').text('New Order').attr('href', 'products');
            this.$popup.find('.js-link-orders').attr('href', 'orders');
            this.$popup.find('.js-link-delivery').addClass('hidden');
            this.$popup.find('.js-link-finance-event').attr('href', 'events-finance-manager');
            this.$popup.find('.js-link-finance-page').attr('href', 'finance-manager');
            this.$popup.find('.js-link-marketing').attr('href', 'design-request');
            this.$popup.find('.js-link-grow-feedback').attr('href', 'CM-feedback');
            this.$popup.find('.js-link-grow-venue-prest').attr('href', 'present');
            this.$popup.find('.js-link-grow-eomGuides').attr('href', 'EOM');
        }


        // set active link dynamicaly from the url
        // options.activeLink && this.$popup.find(`.js-link-${options.activeLink}`).addClass('active');

        this.bindEvents();
    },


    /**
     * Open popup
     * @param e {Event} click event object
     */
    openPopup(e) {
        e && e.preventDefault();
        this.$modal.open();
    },

    /**
     * Close popup
     * @param e {Event} click event object
     */
    closePopup(e) {
        e && e.preventDefault();
        this.$modal.close();
    },

    /**
     * Bind Events
     */
    bindEvents() {
        $('.js-open-popup-admin').on('click', this.openPopup.bind(this));
        $('.js-close-popup-admin').on('click', this.closePopup.bind(this));
    }
};


export default AdminPopup;
