import 'bootstrap-validator';
import Auth from 'utils/auth';

'use strict';

const AuthCard = {

    /**
     * Initialize the AuthCard features
     */
    init(options) {
        this.opts = options || {};

        this.$loginForm = $('#login-form');
        this.$regForm = $('#register-form');

        this.$card = $('.auth-card').removeClass('hidden');
        let cities = [];


        for (let i in this.opts.cities) {
            if (this.opts.cities.hasOwnProperty(i)) {
                cities = cities.concat(this.opts.cities[i].map((city) => {
                    return {
                        id: city.id,
                        name: city.name
                    };
                }));
            }
        }
        cities = cities.filter((city) => {
            return city.id !== 88;
        });
        this.richAutocompleteOptions(cities, $('#reg-city'));
        this.activateValidator();
        this.bindEvents();
    },

    richAutocompleteOptions(data, $elem, type) {
        let autocompleteOptions = {
            maxHeight: 200,
            items: data,
            paging: false,
            pageSize: 0,
            showSpinner: true,
            debounce: 500,
            extractText: (item) => {
                return item.name;
            },
            render: (item) => {
                return '<p>' + item.name + '</p>';
            },
            filter: (items, searchTerm) => {
                let results = items.filter((item) => {
                    return item.name.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1;
                });

                if (results.length !== 1) {
                    $elem.closest('.form-group').removeClass('is-valid');
                }

                return results;
            },
            select: (item) => {
                if ($elem.val().trim() !== '') {
                    $elem.closest('.form-group').addClass('is-valid');
                    $elem.data('value', item.id);
                    $elem.blur();
                }
            }
        };

        $elem.richAutocomplete(autocompleteOptions);
    },

    /**
     * Activate the form validator plugin
     */
    activateValidator() {
        this.$regForm
            .add(this.$loginForm)
            .off('submit')
            .validator('destroy')
            .validator({
                disable: false,
                feedback: {
                    success: 'fa-check',
                    error: 'fa-remove'
                }
            });

        // process the registration form submission
        this.$regForm.on('submit', this.handleRegFormSubmit.bind(this));

        // process the login form submission
        this.$loginForm.on('submit', this.handleLoginSubmit.bind(this));
    },


    /**
     * Process the login form submission
     */
    loginSubmit() {

        const email = this.$loginForm.find('#log-email').val().trim(),
            password = this.$loginForm.find('#log-pass').val().trim();
        this.email = this.$loginForm.find('#log-email').val().trim();

        this.opts.openLoader();

        $('body').addClass('is-loading');

        Auth.login(email, password, 0, (hasError, response) => {
            this.opts.closeLoader();
            if (!hasError) {
                let city = response.data.cities[0].name;
                let stringNameMU = city.replace(' ', '-');
                let baseUrl = window.location.origin;

                let redir = `${baseUrl}/city/${stringNameMU}`;
                window.location.href = redir;
            }
            else {
                if (response.status === 401) {
                    $('body').removeClass('is-loading');
                    $('.js-login-alert').text('You have entered either a wrong email or password. Please try again').slideDown(300).delay(7000).slideUp(200);
                }
                else if (response.status === 422) {
                    $('body').removeClass('is-loading');
                    $('.js-login-alert').text('You first need to verify your email address').slideDown(300).delay(7000).slideUp(200);
                    this.resendEmail();
                    this.showNewStatus();
                }
                else {
                    $('body').removeClass('is-loading');
                    $('.js-login-alert').text('There was an error in processing your request.').slideDown(300).delay(7000).slideUp(200);
                }
            }
        });
    },

    showNewStatus() {
        $('.js-resend-email').removeClass('hidden');
        $('.hideThis').detach();
        $('.show-info').removeClass('hidden');
        $('.singin').detach();

        $('.show-info').text(`Welcome to the community! \n We sent an Email to your address [${this.email}] to make sure it's yours. Please check your inbox and follow the confirmation link provided in that Email. If you dont receive the email: use the resend button.`);

    },

    resendEmail() {
        let email = this.$loginForm.find('#log-email').val().trim();
        if (email.length <= 0) {
            email = this.email;
        }

        window.Api.User.reSendVerifyLink({
            'email': email
        }).then((res) => {
            if (res.isOk) {
                $('.js-login-alert').text('Email sent. Please, check your inbox.').slideDown(300).delay(7000).slideUp(200);
            }
            else {
                $('.js-login-alert').text('There was an error sending your Email. Try later.').slideDown(300).delay(7000).slideUp(200);
            }
        }, () => {
            $('.js-login-alert').text('There was an error sending your Email. Try later.').slideDown(300).delay(7000).slideUp(200);
        });
    },

    sendAgain(e) {
        e && e.preventDefault();
        $('.js-resend-email').detach();
        let that = this;

        window.Api.User.reSendVerifyLink({
            'email': this.email
        }).then((res) => {
            if (res.isOk) {
                $('.js-login-alert').text('Please, check your inbox. Wait for 2 min to send again the email if you need so.').slideDown(300).delay(7000).slideUp(200);
            }
            else {
                $('.js-login-alert').text('There was an error sending your Email. Try later.').slideDown(300).delay(7000).slideUp(200);
            }
        }, () => {
            $('.js-login-alert').text('There was an error sending your Email. Try later.').slideDown(300).delay(7000).slideUp(200);
        });

        setTimeout(function e() {
            $('.addButton').prepend('<a href="#" class=\'btn btn-primary js-resend-email\'>Resend Email</a>');
            that.bindEvents();
        }, 120000);

    },


    /**
     * Handle the login form submission
     * @param {Object} e - the "submit" event object
     */
    handleLoginSubmit(e) {

        /* istanbul ignore else  */
        if (!e.isDefaultPrevented()) {

            // after successful form validation proceed with submission
            e.preventDefault();
            this.loginSubmit();
        }
    },

    updateProfileData() {
        this.data = {
            'first_name': this.$regForm.find('#reg-first-name').val().trim(),
            'last_name': this.$regForm.find('#reg-last-name').val().trim(),
            'avatar': '/images/dummy-avatar.jpg'
        };

        const cityId = [parseInt(this.$regForm.find('#reg-city').data('value'))];


        window.Api.User.me().then((userMe) => {
            if (userMe.isOk) {
                window.Api.User.updateOne(userMe.data.id, this.data).then((res) => {
                    window.Api.CityUser.sync({
                        'user_id': userMe.data.id,
                        'city_ids': cityId
                    }).then((res2) => {
                        this.opts.closeLoader();
                        // $('.js-register-alert').text('We sent a verification email to your inbox.').slideDown(300);
                        this.showNewStatus();
                    }, () => {
                        this.opts.closeLoader();
                        // $('.js-register-alert').text('We sent a verification email to your inbox.').slideDown(300);
                        this.showNewStatus();
                    });
                }, () => {
                    this.opts.closeLoader();
                    // $('.js-register-alert').text('We sent a verification email to your inbox.').slideDown(300);
                    this.showNewStatus();
                });
            }
            else {
                this.opts.closeLoader();
                // $('.js-register-alert').text('We sent a verification email to your inbox.').slideDown(300);
                this.showNewStatus();
            }
        }, () => {
            this.opts.closeLoader();
            // $('.js-register-alert').text('We sent a verification email to your inbox.').slideDown(300);
            this.showNewStatus();
        });
    },


    /**
     * Process the register form submission
     */
    regSubmit() {
        const email = this.$regForm.find('#reg-email').val().trim(),
            password = this.$regForm.find('#reg-pass').val().trim();
        this.email = email;

        this.opts.openLoader();

        Auth.register(email, password, (hasError, response) => {

            if (!hasError && window.localStorage.redirectUrl) {
                $('#reg').click();
                this.opts.closeLoader();
                let url = window.localStorage.redirectUrl;

                window.localStorage.removeItem('redirectUrl');
                window.location.href = url;
            }
            else if (!hasError) {
                $('#reg').click();
                this.updateProfileData();
            }
            else {
                this.opts.closeLoader();
                $('.js-register-alert').text('Invalid email, please use another email address.').slideDown(300).delay(7000).slideUp(200);
            }
        });
    },



    /**
     * Handle the registration form submission
     * @param {Object} e - the "submit" event object
     */
    handleRegFormSubmit(e) {

        /* istanbul ignore else  */
        if (!e.isDefaultPrevented()) {
            if ($('#reg-city').closest('.form-group').hasClass('is-valid')) {
                // after successful form validation proceed with submission
                e.preventDefault();
                this.regSubmit();
            }
            else {
                e.preventDefault();
                $('#reg-city').closest('.form-group').addClass('has-error has-danger');
            }
        }
        else if (!$('#reg-city').closest('.form-group').hasClass('is-valid')) {
            $('#reg-city').closest('.form-group').addClass('has-error has-danger');
        }
    },


    /**
     * Method to show/hide the form tab pane
     * @param {string} paneId - the id selector of the tab pane to show (required)
     * @param {Boolean} noAnimation - if true then no transition animation will be applied
     */
    showFormPane(paneId, noAnimation) {
        const $panes = $('div.auth-card-pane'),
            $paneToHide = $panes.filter('.is-active'),
            $paneToShow = $(paneId);

        $paneToHide.removeClass('is-active');
        $paneToShow.addClass('is-active');

        if (!noAnimation) {
            $panes.addClass('is-animating');
            $paneToHide.addClass('is-exiting');
        }

        $paneToShow.on('transitionend webkitTransitionEnd', this.handlePaneTransitionEnd.bind(this, $paneToShow, $paneToHide));
    },

    /**
     * Remove classes from the tab panes after the transition animation completes
     * @param {Object} $paneToShow - the jQuery selector for the pane that becomes visible
     * @param {Object} $paneToHide - the jQuery selector for the pane that becomes hidden
     */
    handlePaneTransitionEnd($paneToShow, $paneToHide) {
        $paneToHide.removeClass('is-animating is-exiting');
        $paneToShow.removeClass('is-animating is-exiting').off('transitionend webkitTransitionEnd');
    },


    /**
     * Change login / register tabs
     * @param e {Event} click event object
     */
    changeTab(e) {
        e && e.preventDefault();
        this.$card.find('.js-tab-link').removeClass('active');
        $(e.target).addClass('active');

        let pane = '#' + $(e.target).prop('href').split('#')[1];

        this.showFormPane(pane);
    },


    /**
     * Bind Events
     */
    bindEvents() {
        this.$card.on('click', '.js-tab-link', this.changeTab.bind(this));
        $('.js-resend-email').on('click', this.sendAgain.bind(this));
    }
};


export default AuthCard;
