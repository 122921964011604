import Auth from 'utils/auth';
import { redirect, getFormattedDate } from 'utils/helpers';
import { codeToRole } from 'config/constants';

'use strict';

const ProfileCard = {

    /**
     * Initialize the ProfileCard features
     */
    init(options) {
        this.options = options || {};

        this.$card = $('.profile-card').removeClass('hidden');
        this.$sprite = this.$card.find('.sprite-container').clone();
        this.$card.find('.sprite-container').remove();

        if (this.options.userMe) {
            this.renderUser();
        }
        this.bindEvents();
    },

    renderUser() {
        this.$card.find('.js-profile-avatar').attr('src', this.options.userMe.avatar);
        this.$card.find('.js-profile-name').text(`${this.options.userMe.first_name}, ${this.options.userMe.last_name}`);
        this.$card.find('.js-profile-join-date').text(getFormattedDate(this.options.userMe.created_at));
        this.$card.find('.js-profile-role').text(codeToRole[this.options.userMe.role_type]);

        // new info
        let rps = this.options.userMe.rps.length;
        let solutions = 0;
        let harvested = 0;

        if (this.options.userMe.solutions && this.options.userMe.solutions.length > 0) {
            this.options.userMe.solutions.forEach((action) => {
                solutions = solutions + action.length;
            });
        }
        if (this.options.userMe.harvested && this.options.userMe.harvested.length > 0) {
            this.options.userMe.harvested.forEach((harvest) => {
                harvested = harvested + harvest.length;
            });
        }

        this.$card.find('.js-profile-angels').text(rps);
        this.$card.find('.js-profile-solutions').text(solutions);
        this.$card.find('.js-profile-harvest').text(harvested);
        let $firstClone = $(document).find('.js-clone-resident').clone().removeClass('hidden');

        this.options.userMe.cities.forEach((city) => {
            let $clone = $firstClone.clone();
            $clone.find('.js-city-resident').text(city.name);
            this.$card.find('.js-profile-resident').append($clone);
        });

        // this.$card.find('.js-profile-resident').append();
        // this.$card.find('.js-profile-resident').text(this.options.userMe.cities.map((city) => {
        //     return city.name;
        // }).join(' | '));

        // let $flags = [];

        // this.options.userMe.flags.forEach((flag) => {
        //     let $clone = this.$sprite.clone();

        //     $clone.find('.sprite').addClass(`sprite-${flag.image}`);
        //     $flags.push($clone);
        // });
        // this.$card.find('.js-profile-languages').append($flags);
    },


    /**
     * Handle user logout
     * @param e {Event} click event object
     */
    handleLogout(e) {
        e && e.preventDefault();

        this.options.openLoader();

        Auth.logout((error) => {
            if (!error) {
                redirect('/');
            }
            else {
                this.options.closeLoader();
            }
        });
    },

    goToProfile(e) {
        e && e.preventDefault();
        let url = window.location.origin;
        redirect(`${url}/profile/${this.options.userMe.id}`);
    },

    /**
     * Bind Events
     */
    bindEvents() {
        this.$card.on('click', '.js-handle-logout', this.handleLogout.bind(this));
        this.$card.on('click', '.js-my-profile', this.goToProfile.bind(this));
    }
};


export default ProfileCard;
