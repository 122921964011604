import 'remodal';
'use strict';

const Confirmbox = {

    /**
     * Initialize the Confirmbox features
     */
    init() {
        this.$element = $('#confirm-box');
        this.$confirm = this.$element.remodal({
            hashTracking: false,
            closeOnOutsideClick: false
        });

        this.bindEvents();
    },


    /**
     * Open confirmation box
     * @param options {Object}
     */
    open(options) {
        // set texts
        if (options.action && options.item) {
            this.$element.find('.js-text').empty();
            this.$element.find('.js-text').text('Are you sure you want to ');
            this.$element.find('.js-text').append(' <span class="js-confirm-action"> </span> ');
            this.$element.find('.js-text').append(' <strong class="js-confirm-item"> </strong> ');
            this.$element.find('.js-text').append(' <span class="js-sign"> </span>');
            this.$element.find('.js-confirm-action').text(options.action);
            this.$element.find('.js-confirm-item').text(options.item);
            this.$element.find('.js-sign').text('?');
        }
        else {
            this.$element.find('.js-text').text('Wow, congratulations on your huge event! You put those figures in correctly right?');
        }

        if (options.info) {
            this.$element.find('.js-confirm-info').removeClass('hidden').text(options.info);
        }
        else {
            this.$element.find('.js-confirm-info').addClass('hidden');
        }

        // set onAccept and onDecline callbacks
        this.acceptFn = options.onAccept;
        this.declineFn = options.onDecline;

        // open modal
        this.$confirm.open();
    },


    /**
     * Accept button is clicked
     * @param e {Event} click event object
     */
    acceptAction(e) {
        e.preventDefault();
        this.$confirm.close();
        typeof this.acceptFn === 'function' && this.acceptFn();
    },


    /**
     * Decline button is clicked
     * @param e {Event} click event object
     */
    declineAction(e) {
        e.preventDefault();
        this.$confirm.close();
        typeof this.declineFn === 'function' && this.declineFn();
    },


    /**
     * bind events
     */
    bindEvents() {
        this.$element.on('click', '.js-confirm-decline', this.declineAction.bind(this));
        this.$element.on('click', '.js-confirm-accept', this.acceptAction.bind(this));
    }
};


export default Confirmbox;
