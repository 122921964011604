import throttle from 'throttle-debounce/throttle';
import MenuPopup from 'components/menu-popup';
import CitiesPopup from 'components/cities-popup';
import AdminPopup from 'components/admin-popup';
import Auth from 'utils/auth';
import { redirect } from 'utils/helpers';

'use strict';

const HeaderNav = {

    /**
     * Initialize the HeaderNav features
     */
    init(options) {
        this.options = options || {};

        this.df = $.Deferred();

        window.Api.addResponseInterceptor(({ request, status }) => {
            if (request.responseURL.indexOf('auth') === -1 && request.responseURL.indexOf('oauth') === -1) {
                if (status === 401) {
                    Auth.logout(() => {
                        redirect('/');
                    });
                }
            }
        });

        this.loadData();

        this.changeNavbarScrollState = throttle(200, this.changeNavbarScrollState);
        this.setupNavbarScrollStateChange();


        return this.df.promise();
    },


    /**
     * Load selected city information (if any)
     * And then load user related information
     */
    loadCity() {
        if (this.options.cityName) {
            window.Api.City.getOne(this.options.cityName).then((city) => {
                if (city.isOk) {
                    this.options.city = city.data;
                }
                this.loadUser();
            }, () => {
                this.loadUser();
            });
        }
        else {
            this.loadUser();
        }
    },


    /**
     * Load user information and initiate sub-elements
     * and user permissions
     */
    loadUser() {
        if (this.options.isLoggedIn) {
            window.Api.User.me().then((res) => {
                if (res.isOk) {
                    this.options.userMe = res.data;
                    this.options.isAdmin = res.data.role_type == 4;
                    this.options.isSupplier = res.data.role_type == 3;
                    this.options.isPhotographer = res.data.role_type == 6;
                    this.options.isAmbassador = res.data.role_type == 7;

                    if (res.data.role_type == 2) {
                        this.options.isManager = res.data.role_type == 2;
                    }
                    if (res.data.role_type == 5) {
                        this.options.isManager = res.data.role_type == 5;
                    }
                    if (res.data.role_type == 3 || res.data.role_type == 4) {
                        this.options.isManager = false;
                    }
                    this.df.resolve(true);
                }

                CitiesPopup.init(this.options);
                MenuPopup.init(this.options);
                AdminPopup.init(this.options);
                this.df.resolve(false);
            }, () => {
                CitiesPopup.init(this.options);
                MenuPopup.init(this.options);
                AdminPopup.init(this.options);
                this.df.resolve(false);
            });
        }
        else {
            CitiesPopup.init(this.options).then((cities) => {
                this.options.cities = cities;
                MenuPopup.init(this.options);
            });
            AdminPopup.init(this.options);
            this.df.resolve(true);
        }
    },



    /**
     * Load data from API
     */
    loadData() {
        Auth.checkAuthState((isLoggedIn) => {
            this.options.isLoggedIn = isLoggedIn;
            this.options.userMe = { 'role_type': 0, id: 0 };
            this.loadCity();
        });
    },


    /**
     * Get selected city if any
     * @returns {Object}
     */
    getCity() {
        return this.options.city;
    },

    /**
     * Get permission object
     * @returns {{isLoggedIn: *, isAdmin: *, isSupplier: *, isManager: *, isPhotographer: *}}
     */
    permissions() {
        return {
            isLoggedIn: this.options.isLoggedIn,
            isAdmin: this.options.isAdmin,
            isSupplier: this.options.isSupplier,
            isManager: this.options.isManager,
            isPhotographer: this.options.isPhotographer,
            isAmbassador: this.options.isAmbassador,
        };
    },


    /**
     * Get user me object
     * @returns {Object}
     */
    me() {
        return this.options.userMe;
    },

    /**
     * Add/remove class on the navbar when scrolling
     * @param {Object} $headerNav - jQuery selector for the header navbar
     */
    changeNavbarScrollState($headerNav) {
        if ($(window).scrollTop() > 0) {
            $headerNav.addClass('is-scrolled');
        }
        else {
            $headerNav.removeClass('is-scrolled');
        }
    },

    /**
     * When scrolling down change navbar styling
     */
    setupNavbarScrollStateChange() {
        var self = this,
            $headerNav = $('header.header');

        $(window).scroll(self.changeNavbarScrollState.bind(self, $headerNav)).trigger('scroll');
    },
};


export default HeaderNav;
