import { apiUrl } from 'config/constants';
import { redirect } from 'utils/helpers';
import Auth from 'utils/auth';

'use strict';

/**
 * Method for making ajax calls to the site's api
 * @param {string} endpoint - the api endpoint
 * @param {Object|string} [data=null] - key:value pairs of the data to be sent to server
 * @param {string} [type=get] - the type of ajax request to make
 * @param {Boolean} [isAuthNeeded=false] - determines if the user needs to be authorized
 * @param {Boolean} [isLogoutNeeded=true] - determines if the user will be logged out and redirected when there is 401 error
 * @returns {Object} jQuery ajax promise object
 */
export default function makeApiRequest(endpoint, data = null, type = 'get', isAuthNeeded = false, isLogoutNeeded = true) {
    const settings = {
        url: apiUrl + endpoint,
        type: type,
        data: data,
        dataType: 'json',
        xhrFields: {
            withCredentials: true
        }
    };

    let req = $.Deferred();

    if (isAuthNeeded) {
        $.ajax(settings)
            .then((response) => {
                req.resolve(response);
                return $.Deferred().resolve(false).promise();
            }, (jqxhr) => {

                // on "401" Unauthorized error proceed to next step
                if (jqxhr.status === 401) {
                    return $.Deferred().resolve(true).promise();
                }
                else {

                    // for other kinds of errors reject the request
                    req.reject(jqxhr);
                    return $.Deferred().resolve(false).promise();
                }
            })
            .then((isUnauthError) => {

                // don't proceed if there is no "401" error or if logout is not needed
                if (!isUnauthError || !isLogoutNeeded) {
                    return;
                }

                // if user unauthorized then logout the user and redirect to homepage
                Auth.logout((hasError) => {
                    /* istanbul ignore else */
                    if (!hasError) {
                        redirect('/');
                    }
                });
            });
    }
    else {
        $.ajax(settings)
            .done((response) => req.resolve(response))
            .fail((jqxhr) => req.reject(jqxhr));
    }

    return req;
}
