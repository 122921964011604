import HeaderNav from 'components/header-nav';

'use strict';

const LandingView = {
    /**
     * Initialize the view
     */
    init() {

        $.material.init();
        $('body').removeClass('hidden');
        HeaderNav.init();

        /* new - classic remodal */
        this.classicNew = $('#classic-new-page');
        this.verified = $('#verified-user');
        // this.classicRemodal = this.classicNew.remodal({ hashTracking: false });
        this.verifiedRemodal = this.verified.remodal({ hashTracking: false });
        this.bindEvents();
        let verified = window.location.href.includes('verified');

        // old popup - not used anymore
        if (verified) {
            this.verifiedRemodal.open();
        }
        // else {
        //     const classicPopup = sessionStorage.getItem('dontShow');
        //     if (!classicPopup || classicPopup === 'false') {
        //         this.classicRemodal.open();
        //     }
        // }
    },

    closeNewPageRemodal(e) {
        e.preventDefault();
        this.classicRemodal.close();
    },

    closeVerifiedRemodal(e) {
        e.preventDefault();
        this.verifiedRemodal.close();
    },

    gup(name, url) {
        if (!url) {
            url = window.location.href;
        }
        name = name.replace(/[\[]/, '\\\[').replace(/[\]]/, '\\\]');
        var regexS = '[\\?&]' + name + '=([^&#]*)';
        var regex = new RegExp(regexS);
        var results = regex.exec(url);
        return results == null ? null : results[1];
    },

    dontShowAgain(e) {
        if (e.target.checked) {
            sessionStorage.setItem('dontShow', true);
        }
        else {
            sessionStorage.setItem('dontShow', false);
        }
    },

    bindEvents() {
        $(document).on('click', '.js-close-popup-new-page', this.closeNewPageRemodal.bind(this));
        $(document).on('click', '.js-close-popup-verified', this.closeVerifiedRemodal.bind(this));
        $(document).on('change', '.js-dont-show-again', this.dontShowAgain.bind(this));
    }
};

/* start-test */
/* istanbul ignore if  */
if (!window.__karma__) {
    /* end-test */
    LandingView.init();
    /* start-test */
}

/* end-test */
export default LandingView;
